import React, { useEffect, useState, createRef } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import * as AnimationStuff from "./AnimatedStuff.js";
import * as SessionManager from "./SessionManager";
import HamburgerMenu from "react-hamburger-menu";
import loadable from "@loadable/component";
import Loader from "./Loader.js";
import PlayerCountView from "./PlayerCountView.js";
import "./App.css";
import YearInReview from "./YearInReview.js";
import LoginForm from "./Accounts/LoginForm.js";
import ErrorBoundary from "./ErrorBoundary.js";

const Home = loadable(() => import("./Home.js"), {
  fallback: <Loader />,
});
const SupportPage = loadable(() => import("./SupportPage.js"), {
  fallback: <Loader />,
});

const StaffView = loadable(() => import("./StaffView.js"), {
  fallback: <Loader />,
});
const Accounts = loadable(() => import("./Accounts/Accounts.js"), {
  fallback: <Loader />,
});
const Rules = loadable(() => import("./Rules.js"), {
  fallback: <Loader />,
});
const Bans = loadable(() => import("./Bans.js"), {
  fallback: <Loader />,
});
const VoteLinks = loadable(() => import("./VoteLinks.js"), {
  fallback: <Loader />,
});
const AntiCheatLogin = loadable(() => import("./Accounts/LoginAC.js"), {
  fallback: <Loader />,
});
const Leaderboards = loadable(() => import("./Leaderboards.js"), {
  fallback: <Loader />,
});

const Forums = loadable(() => import("./ForumsView.js"), {
  fallback: <Loader />,
});
const BlogApp = loadable(() => import("./Blog/BlogApp"), {
  fallback: <Loader />,
});
const HelpApp = loadable(() => import("./HelpApp/HelpCore"), {
  fallback: <Loader />,
});
const StatusApp = loadable(() => import("./Status"), {
  fallback: <Loader />,
});
const Feedbacks = loadable(() => import("./Feedbacks"), {
  fallback: <Loader />,
});
const StaffFeedback = loadable(() => import("./StaffFeedback"), {
  fallback: <Loader />,
});
const ServerFeedback = loadable(() => import("./ServerFeedback"), {
  fallback: <Loader />,
});
const PersonalYIR = loadable(() => import("./PersonalYIR"), {
  fallback: <Loader />,
});
const StatusPage = loadable(() => import("./Status"), {
  fallback: <Loader />,
});
const TicketView = loadable(() => import("./HelpApp/Ticket"), {
  fallback: <Loader />,
});

export default function KingsCraftReact() {
  let navBarType = "NavBar";
  const navRef = createRef();
  const mNavRef = createRef();
  const [logged, setLogged] = useState(false)
  function mobileNavToggle() {
    setMobileNavOpen(!mobileNavOpen);
  }
  function removeMobileNav() {
    setMobileNavOpen(false);
  }
  useEffect(() => {
    SessionManager.isAutenticated().then((res) => {
      if (res) {
        setLogged(true);
      } 
    });
    return function cleanup() {};
  }, []);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [navbarState, setNavBarState] = useState(true);

  function setNav(val) {
    console.log("call", val);
    setNavBarState(val);
  }

  if (navbarState) {
    navBarType = "NavBar";
  } else {
    navBarType = "NavBar hideNavBar";
  }
  let navMobile = "navMobile";
  let mainSwitch = "mainSwitch";

  if (mobileNavOpen) {
    navMobile = "navMobile navMobileOpened";
    mainSwitch = "mainSwitch transformedSwitch";
  } else {
    navMobile = "navMobile";
    mainSwitch = "mainSwitch";
  }
  let loginPanel;
  if (logged) {
    loginPanel = <Link to="/accounts">{SessionManager.getUsername()}</Link>
  } else {
    loginPanel = <Link to="/accounts/login">Login</Link>
  }
  return (
    <Router>
      <a className="skip-link" href="#maincontent">
        Skip to main
      </a>

      <div className={navMobile}>
        <Link onClick={removeMobileNav} to="/">
          HOME
        </Link>
        <Link onClick={removeMobileNav} to="/help/">
          HELP
        </Link>
        <Link onClick={removeMobileNav} to="/blog/">
          BLOG
        </Link>

        <Link onClick={removeMobileNav} to="/vote">
          VOTE
        </Link>
        <a
          onClick={removeMobileNav}
          rel="noreferrer"
          target="_blank"
          href="https://store.kings-craft.com/"
        >
          STORE
        </a>
        {/*<Link onClick={removeMobileNav} to="/rules">RULES</Link>*/}
        {/*<Link onClick={removeMobileNav} to="/staff">STAFF</Link>*/}
        <a
          onClick={removeMobileNav}
          rel="noreferrer"
          target="_blank"
          href="https://discord.com/invite/Xyq6uJ2"
        >
          DISCORD
        </a>
        <Link onClick={removeMobileNav} to="/bans">
          BANS
        </Link>
        <Link onClick={removeMobileNav} to="/feedback">
          Feedback
        </Link>
        <Link onClick={removeMobileNav} to="/status">
          Status
        </Link>
        <PlayerCountView />
        <div onClick={removeMobileNav}>
        {loginPanel}
        </div>
      </div>

      <div className={navBarType}>
        <div>
          
        </div>
        <div id="NavLarge">
          <Link className="logoDiv" to="/"></Link>

          <Link to="/help/">Help</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/vote">Vote</Link>

          <a
            rel="noreferrer"
            target="_blank"
            href="https://store.kings-craft.com/"
          >
            Store
          </a>
          {/*<Link to="/rules">Rules</Link>*/}
          {/*<Link to="/staff">Staff</Link>*/}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://discord.com/invite/Xyq6uJ2"
          >
            Discord
          </a>
          <Link to="/bans">Bans</Link>
          <Link to="/feedback">Feedback </Link>
          <Link to="/status">Status</Link>
          <div className="rightPanel">
            <PlayerCountView />
            {loginPanel}
          </div>
        </div>
       
        <div id="NavSmol">
          <div className="KCInput">
            <HamburgerMenu
              isOpen={mobileNavOpen}
              menuClicked={mobileNavToggle}
              width={18}
              height={15}
              strokeWidth={1}
              rotate={0}
              color="black"
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>

          <Link className="logoDiv smolDiv" to="/"></Link>
          <div className="header">KingsCraft</div>
        </div>
      </div>
      <div id="NavUnderLarge">
        <div>
            
        </div>
      </div>
      <AnimatedSwitch
        atEnter={AnimationStuff.bounceTransition.atEnter}
        atLeave={AnimationStuff.bounceTransition.atLeave}
        atActive={AnimationStuff.bounceTransition.atActive}
        mapStyles={AnimationStuff.mapStyles}
        className={mainSwitch}
      >
        <Route path="/staff">
          <StaffView />
        </Route>
        <Route path="/forums">
          <Forums />
        </Route>
        <Route path="/vote">
          <VoteLinks />
        </Route>
        <Route path="/bans">
          <Bans />
        </Route>
        <Route path="/loginAC">
          <AntiCheatLogin setNav={setNav}/>
        </Route>
        <Route exact path="/status" component={StatusApp} />
        <Route path="/blog" component={BlogApp}></Route>
        <Route exact path="/support/tickets" component={TicketView}></Route>
        <Route path="/help" component={HelpApp}></Route>
        <Route path="/rules">
          <Rules />
        </Route>
        <Route path="/yearinreview">
          <ErrorBoundary>
            <YearInReview setNavBar={setNav} />
          </ErrorBoundary>
          
        </Route>
        <Route path="/leaderboards">
          <Leaderboards />
        </Route>

        <Route path="/youryearinreview">
        <ErrorBoundary>
          <PersonalYIR />
          </ErrorBoundary>
        </Route>

        <Route exact path="/feedback">
          <Feedbacks />
        </Route>
        <Route exact path="/feedback/staff">
          <StaffFeedback />
        </Route>
        <Route exact path="/feedback/server">
          <ServerFeedback />
        </Route>
        <Route exact path="/accounts">
          <Accounts setLogged={setLogged}/>
        </Route>
        <Route exact path="/support">
          <SupportPage setLogged={setLogged}/>
        </Route>
        <Route exact path="/status">
          <StatusPage/>
        </Route>
        <Route exact path="/accounts/login">
          <LoginForm setLogged={setLogged}/>
        </Route>

        <Route exact path="/">
          <Home />
        </Route>
        <Route
          render={function () {
            return <p style={{ padding: "90px" }}>Route not found.</p>;
          }}
        />
      </AnimatedSwitch>
    </Router>
  );
}
