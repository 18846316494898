 import {spring} from 'react-router-transition';
 // ANIMATION STUFF
 
 export function bounce(val) {
 
    return spring(val, {
      stiffness: 330,
      damping: 22,
    });
  }
   
  
  // child matches will...
 export const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
      opacity: 0,
      offset: 50,
    },
    // leave in a transparent, downscaled state
    atLeave: {
      opacity: bounce(0),
      offset: bounce(-50),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
      opacity: bounce(1),
      offset: bounce(0),
    },
  };
  
 export function mapStyles(styles) {
    return {
      opacity: styles.opacity,
      transform: `translateX(${styles.offset}%)`,
    };
  }