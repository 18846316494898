import ReCAPTCHA from "react-google-recaptcha";
import { createRef, useState } from "react";
import { loginVerify, isAutenticated } from "../SessionManager";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
export default function LoginForm(props) {
    isAutenticated()
    .then((res) => {
      if (res) {
        props.setLogged(true);
        history.push("/")
      } 
    })
    let captchaToken;
    let usernameRef = createRef();
    let passwordRef = createRef();
    let captchaRef = createRef();
    const history = useHistory();
    const [errorMessage,setErrorMessage] = useState("")
    function captchaVerify(token) {
        console.log("Captcha Token", token)
        captchaToken = token;
    }
    function verifyLoginAccount() {
        loginVerify(usernameRef.current.value, passwordRef.current.value, captchaToken)
        .then (function(res) {
 
            if (res.data.state === "error") {
                setErrorMessage("Error: " + res.data.error)
                
            } else if (res.data.state === "success") {
                props.setLogged(true);
                history.goBack();
                
            }
        })
        if (captchaRef.current) captchaRef.current.reset();

    }
    return(
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Login - KingsCraft PH</title>
            <meta
                 name="description"
                 content="Manage your accounts here."
            />
            <link rel="canonical" href="https://kings-craft.com/accounts/login" />
          </Helmet>
        <div className="hasBlur">
            <div className="Centerer">
            <div className="smallBorder">
            <div className="kcb">
                    You are logged out.<br/>
                    Please login with your in-game credentials.
                </div>
                <div className="KCFormsHeader">Minecraft Username</div>
                <input ref={usernameRef} className="KCInput KCBlock"></input>
                <div className="KCFormsHeader">/login Password</div>
                <input
                    ref={passwordRef}
                    type="password"
                    className="KCInput KCBlock"></input><br/>
                <ReCAPTCHA
                    ref={captchaRef}
                    sitekey="6Ldqh6AaAAAAAI2P6PFqOTmLodbwxXsx55t9e1lH"
                    onChange={captchaVerify}
                />
                <button
                    onClick={verifyLoginAccount} 
                    className="button KCSpaced">Login
                </button>
                <div className="kcb">{errorMessage}</div>
            </div>
            </div>
        </div>
        </>

       
    )
}