import axios from "axios";
import { useEffect, useState } from "react";

export default function PlayerCountView() {
  const [playerData, setPlayerData] = useState({});

  useEffect(() => {
    function updateData() {
      axios
        .get("https://api.kings-craft.com/v1/getPlayerCount/")
        .then((res) => {
          setPlayerData(res.data);
        })
        .catch(() => {
            setPlayerData({});
        })
    }
    updateData();
    let updater = setInterval(updateData, 5000);
    return function cleanup() {
      clearInterval(updater);
    };
  }, []);
  if (playerData.players) {
    return (
        <span>Players: <b>{playerData.players.online}</b></span>
      );    
  } else {
      return (
        <span>Offline</span>
      );
  }
  
}
