import { useEffect } from 'react';
import {Link} from 'react-router-dom'
 
export default function YearInReview(props) {
    
    useEffect(() => {
        props.setNavBar(false);
        return function cleanup() {
            props.setNavBar(true);
        };
      });
    return (
        <div>
            <div className="rel">
                <div className="imageWithText">
                    <img src="https://cdnbulk.kings-craft.com/BULKIMAGES/2021-12-24_20.11.38.png"></img>
                    <div>
                        <div className="Centerer header">
                            KingsCraft Year In Review
                        </div>
                    </div>
                </div>
            </div>
            
             
            <div className="Centerer">
            We are thankful for the players that are loyal to the KingsCraft Network, especially for those that stayed with us in every ups and downs we have. <br/>
            W    
            </div>
        </div>
    )
}