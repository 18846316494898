import React from 'react';
import generatedGitInfo from './generatedGitInfo.json';
export default class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error: undefined };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error: error };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
        <div className="fullPageError">
            <div className="pageCentered">
              <center>
                 
                <br/>
                Oh no! An error occured. Please send DM to captainhandsome#0616.<br/>
                Include the error below.<br/>
                <i>Note, if you keep getting this error, your account stats may be incomplete.</i>
                <br/>
                {this.state.error.name}
                <br/>
              </center>
              <textarea readOnly className="err">
    
              {this.state.error.stack}
              </textarea>
              <center>git-{generatedGitInfo.gitCommitHash}-{generatedGitInfo.gitBranch}</center>
            </div>
            
          </div>
        )
 
      }
  
      return this.props.children; 
    }
  }